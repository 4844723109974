import { extend, localize } from "vee-validate";
import { required, min, regex, email } from "vee-validate/dist/rules";

const ja = {
  code: "ja",
  messages: {
    email: "有効なメールアドレスではありません",
    min: "半角英数字の6文字以上で入力してください",
    regex: "半角英数字の6文字以上で入力してください",
    required: "必須項目です",
  },
};

export default function apendExtendRules() {
  // 必要なルールのみインポート
  extend("required", required);
  extend("min", min);
  extend("regex", regex);
  extend("email", email);

  // メッセージを設定
  localize("ja", ja);
}

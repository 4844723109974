<template>
  <v-dialog v-model="dialog" persistent width="auto ">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-0 d-flex justify-end">
        <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text class="px-4 pt-4 pb-0 d-flex">
        <div>
          <v-icon class="mb-1">mdi-lock</v-icon>
          <span class="text--secondary text-body-2">パスワード</span>
          <v-text-field
            class="ma-0 text-field"
            v-if="dialog"
            v-model="password"
            v-bind:type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            :error-messages="errorMessage"
            solo
            autofocus
            @click:append="showPassword = !showPassword"
            @keydown.enter="passwordLogin()"
          />
        </div>

        <v-btn
          class="ml-4 mt-7 primary--text"
          color="primary"
          icon
          x-large
          :loading="loading"
          :disabled="loading"
          @click="passwordLogin()"
        >
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-actions class="d-flex justify-center">
        <v-dialog v-model="isOpenedComfirmDialog" persistent width="auto ">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="text-caption" v-bind="attrs" v-on="on" color="primary" text>
              パスワードを忘れた場合はこちら
            </v-btn>
          </template>
          <v-card class="pa-2 pa-sm-4">
            <v-card-title class="pa-4">
              <span class="text-subtitle-1 text-sm-h6">
                パスワード再設定の案内メールを送信します。
              </span>
            </v-card-title>
            <v-card-text class="px-4 pb-4">送信先：{{ mailAddress }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="text--secondary" text @click="isOpenedComfirmDialog = false">
                閉じる
              </v-btn>
              <v-btn
                color="primary"
                depressed
                :loading="loading"
                :disabled="loading"
                @click="sendPasswordResetMail()"
              >
                送信
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
    <DialogMessage
      :dialog="isOpenedMessageDialog"
      message="パスワード再設定の案内メールを送信しました。"
      @close="closeDialog()"
    />
  </v-dialog>
</template>

<script>
import firebase from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";

export default {
  props: {
    dialog: Boolean,
    mailAddress: String,
    createSession: {
      type: Function,
      required: true,
    },
    openMenu: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    password: "",
    showPassword: false,
    errorMessage: "",
    isOpenedComfirmDialog: false,
    isOpenedMessageDialog: false,
  }),
  methods: {
    /**
     * email,パスワードでログイン
     */
    async passwordLogin() {
      if (this.password.trim() == "") {
        this.errorMessage = "入力してください";
        return;
      }

      this.errorMessage = "";
      this.loading = true;

      const user = this.$store.getters.getUser;
      const shop = JSON.parse(JSON.stringify(this.$store.getters.getShop));
      delete shop.createdAt;
      const headquartersUID = this.$store.getters.getHeadquartersUID;
      if (headquartersUID) shop.headquartersUID = headquartersUID;

      // firebase functionsにて認証処理
      const loginResult = await this.createSession({
        user,
        shop,
        password: this.password,
      });

      if (loginResult == "success") {
        logEvent("login", {
          user_uid: this.$store.getters.getUserUID,
          shop_uid: this.$store.getters.getShopUID,
          window_size: `${window.innerWidth}px * ${window.innerHeight}px`,
          user_agent: navigator.userAgent,
          authority: user.authority,
        });
        this.$emit("close");
        this.openMenu();
      } else if (loginResult == "auth-error") {
        this.errorMessage = "パスワードに誤りがあります\nもう一度入力してください";
      } else {
        this.errorMessage = "ログイン処理にエラーが発生しました";
      }

      this.loading = false;
    },

    /**
     * ダイアログを閉じるときにエラーメッセージを初期化する
     */
    closeDialog() {
      this.password = "";
      this.errorMessage = "";
      this.isOpenedMessageDialog = false;
      this.$emit("close");
    },

    /**
     * パスワードリセットのメール送信
     */
    async sendPasswordResetMail() {
      this.loading = true;
      await firebase
        .auth()
        .sendPasswordResetEmail(this.mailAddress)
        .then(function () {
          console.log("メール送信完了");
        })
        .catch(function (error) {
          console.log(error);
        });
      this.isOpenedComfirmDialog = false;
      this.isOpenedMessageDialog = true;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.card {
  background-color: whitesmoke !important;
}

.text-field {
  max-width: 200px;
}

::v-deep .v-messages {
  white-space: pre-wrap;
}
</style>

<template>
  <v-container class="pa-0" fluid>
    <v-row class="pa-4" no-gutters justify="center">
      <v-col cols="12" sm="11" md="8">
        <v-row no-gutters>
          <v-col class="div-title d-flex align-end text-h4" cols="12" sm="6">
            <v-img
              :src="require('cumin-common/src/assets/harecord.png')"
              contain
              height="40"
              max-width="170"
            />
            <span class="ml-4 text-body-1 text-sm-h6 font-weight-bold">店舗選択</span>
          </v-col>
          <v-col class="text-sm-end pr-2" align-self="end" cols="12" sm="6">
            <span class="text-body-1 text-sm-h6">{{ headquarterName }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex justify-end align-center" cols="12" sm="11" md="8">
        <span class="pt-1 text-no-wrap text-caption">端末設定店舗：</span>
        <div class="pt-1 pr-2">
          <span class="text-caption text-sm-body-1" v-if="favoriteShop.uid">
            {{ favoriteShop.selectShopName }}
          </span>
          <span class="mr-2" v-else>無し</span>
        </div>
        <v-btn
          height="40"
          icon
          :disabled="!selectedShop.uid || selectedShop.uid == favoriteShop.uid"
          @click="$refs.passwordDialog.openMenu(true)"
        >
          <v-icon>mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn
          height="40"
          icon
          :disabled="!favoriteShop.uid"
          @click="$refs.passwordDialog.openMenu(false)"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-menu v-if="isAuthenticated" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" height="40" icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0" color="#f5f5f5">
            <v-list-item link @click="logout()">
              <v-list-item-title class="primary--text">ログアウト</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="pa-4" no-gutters justify="center">
      <v-col cols="12" sm="11" md="8">
        <ul class="ml-sm-n4 pa-0 d-flex">
          <li class="d-flex align-center" v-for="(shop, index) in shops" :key="shop.line">
            <v-btn
              class="pa-0"
              text
              :min-height="$vuetify.breakpoint.xs ? 25 : 40"
              :min-width="$vuetify.breakpoint.xs ? 25 : 40"
              color="primary"
              :disabled="shop.items.length == 0"
              @click="scrollWindow(shop.line)"
            >
              {{ shop.line }}
            </v-btn>
            <v-divider v-if="index < shops.length - 1" class="mx-1" vertical></v-divider>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row class="pt-5" no-gutters justify="center">
      <v-col v-if="!loading" cols="12" sm="11" md="8">
        <div v-for="(shop, index) in shops" :key="shop.line">
          <v-subheader
            class="text-h6 font-weight-bold px-5 px-sm-2"
            :class="index == 0 ? '' : 'mt-8'"
            :ref="shop.line"
          >
            {{ shop.line }}
          </v-subheader>
          <v-row no-gutters class="px-3 px-sm-0">
            <v-col v-for="item in shop.items" :key="item.shopName" cols="12" sm="6" md="4">
              <v-btn
                class="btn-shop px-2 text-start"
                :text="selectedShop.uid != item.uid"
                min-height="40"
                color="primary"
                @click="clickShop(item)"
              >
                <span>{{ item.selectShopName }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <PasswordDialog
      ref="passwordDialog"
      @store="storeFavoriteShop()"
      @clear="clearFavoriteShop()"
    />
    <DialogMessage
      :dialog="messageDialog"
      message="ログアウトしました。"
      @close="messageDialog = false"
    />
    <v-fab-transition>
      <v-btn
        v-show="topBtn"
        v-scroll="onScroll"
        class="pr-4 white primary--text btn-top"
        fab
        fixed
        right
        elevation="2"
        @click="$vuetify.goTo(0)"
      >
        <span class="mr-6" v-if="!$vuetify.breakpoint.xs">Topへ戻る</span>
        <v-icon>mdi-format-vertical-align-top</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        v-show="bottomSheet && !isAuthenticated"
        class="pr-4 white primary--text btn-login"
        fab
        fixed
        right
        elevation="2"
        @click="selectShop(selectedShop)"
      >
        <span class="mr-6" v-if="!$vuetify.breakpoint.xs">ログインへ</span>
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        v-show="isAuthenticated"
        class="pr-4 white primary--text btn-login"
        fab
        fixed
        right
        elevation="2"
        :disabled="updating"
        :loading="updating"
        @click="updateSession()"
      >
        <span class="mr-6" v-if="!$vuetify.breakpoint.xs">メニューへ</span>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-slide-y-reverse-transition>
      <v-sheet
        v-if="bottomSheet"
        height="48px"
        width="100%"
        color="primary"
        class="
          sheet-bottom
          d-flex
          justify-center
          align-center
          white--text
          text-caption text-sm-body-2
        "
      >
        <span>選択中の店舗：{{ selectedShop.selectShopName }}</span>
        <v-btn
          class="ml-3 white--text"
          v-if="selectedShop.uid != favoriteShop.uid"
          height="40"
          icon
          @click="$refs.passwordDialog.openMenu(true)"
        >
          <v-icon>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-sheet>
    </v-slide-y-reverse-transition>
  </v-container>
</template>

<script>
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import PasswordDialog from "./PasswordDialog";

export default {
  props: {
    isAuthenticated: Boolean,
    selectShop: {
      type: Function,
      required: true,
    },
    openMenu: {
      type: Function,
      required: true,
    },
  },
  components: {
    PasswordDialog,
  },
  data: () => ({
    loading: false,
    headquarterName: "",
    favoriteShop: {},
    selectedShop: {},
    topBtn: false,
    bottomSheet: false,
    shops: [
      { line: "ア", value: "アイウエオ", items: [] },
      { line: "カ", value: "カキクケコガギグゲゴ", items: [] },
      { line: "サ", value: "サシスセソザジズゼゾ", items: [] },
      { line: "タ", value: "タチツテトダヂヅデド", items: [] },
      { line: "ナ", value: "ナニヌネノ", items: [] },
      { line: "ハ", value: "ハヒフヘホパピプペポバビブベボ", items: [] },
      { line: "マ", value: "マミムメモ", items: [] },
      { line: "ヤ", value: "ヤユヨ", items: [] },
      { line: "ラ", value: "ラリルレロ", items: [] },
      { line: "ワ", value: "ワヲン", items: [] },
    ],
    updating: false,
    messageDialog: false,
  }),
  created: function () {
    this.favoriteShop.shopName = localStorage.getItem("shopName");
    this.favoriteShop.selectName = localStorage.getItem("selectShopName");
    this.favoriteShop.uid = localStorage.getItem("shopUID");
    this.loadShops();
  },
  methods: {
    onScroll(e) {
      this.topBtn = window.pageYOffset > 0 || e.target.scrollTop > 0 || false;
    },

    /**
     * 店舗一覧の作成
     */
    async loadShops() {
      this.loading = true;
      let shopData = [];
      const headquarters = await db
        .collection("headquarters")
        .doc(this.$route.params.uid)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "loadShops",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      this.headquarterName = headquarters.data().headquarterName;

      const shops = await db
        .collection("shops")
        .where("deletedFlag", "==", false)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "loadShops",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      // 本部情報をDBから取得し、配列に加える
      await Promise.all(
        headquarters.data().shopUID.map(async (docID) => {
          const shop = shops.docs.filter((e) => e.id == docID)[0];

          if (shop != undefined) {
            shopData.push(shop.data());
            shopData[shopData.length - 1].uid = shop.id;
            if (this.favoriteShop.uid == shop.id)
              this.favoriteShop.selectShopName = shop.data().selectShopName;
          }
        })
      );

      // アイウエオ順にソート
      shopData.sort((a, b) => {
        return a.selectShopNameKana.localeCompare(b.selectShopNameKana, "ja");
      });

      // アカサタナ別に配列作成
      shopData.forEach((data) => {
        let initial = data.selectShopNameKana.slice(0, 1);
        this.shops.forEach((shop) => {
          if (shop.value.match(initial)) shop.items.push(data);
        });
      });
      this.loading = false;
    },

    /**
     * 画面スクロール
     */
    scrollWindow(line) {
      const target = this.$refs[line][0].$el.offsetTop;
      this.$vuetify.goTo(target);
    },

    /**
     * 店舗のクリック
     * @param {object} item 選択した店舗
     */
    clickShop(item) {
      this.bottomSheet = true;
      this.selectedShop = {
        uid: item.uid,
        shopName: item.shopName,
        selectShopName: item.selectShopName,
      };
    },

    /**
     * お気に入り店舗のクリア
     */
    async clearFavoriteShop() {
      this.favoriteShop = {
        uid: "",
        shopName: "",
        selectShopName: "",
      };
      localStorage.removeItem("shopUID");
      localStorage.removeItem("shopName");
      localStorage.removeItem("selectShopName");
    },

    /**
     * お気に入り店舗の登録
     */
    async storeFavoriteShop() {
      this.favoriteShop = {
        uid: this.selectedShop.uid,
        shopName: this.selectedShop.shopName,
        selectShopName: this.selectedShop.selectShopName,
      };
      localStorage.setItem("shopUID", this.selectedShop.uid);
      localStorage.setItem("shopName", this.selectedShop.shopName);
      localStorage.setItem("selectShopName", this.selectedShop.selectShopName);
    },

    async updateSession() {
      this.updating = true;

      // セッションの店舗情報を更新
      await db
        .collection("shops")
        .doc(this.selectedShop.uid)
        .get()
        .then((shopDoc) => {
          if (shopDoc.exists) this.$store.commit("setShopInfo", shopDoc);
        })
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "updateSession",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      const user = this.$store.getters.getUser;
      const shop = JSON.parse(JSON.stringify(this.$store.getters.getShop));
      delete shop.createdAt;
      const headquartersUID = this.$store.getters.getHeadquartersUID;
      if (headquartersUID) shop.headquartersUID = headquartersUID;

      // セッションcookieを更新
      await this.$axios
        .post("/update-session", { user, shop })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") this.openMenu();
          if (response.data.status == "error") {
            logEvent("error_login", {
              user_uid: this.$store.getters.getUserUID,
              shop_uid: this.$store.getters.getShopUID,
              method_name: response.data.method,
              error_message: response.data.error,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          logEvent("error_login", {
            user_uid: this.$store.getters.getUserUID,
            shop_uid: this.$store.getters.getShopUID,
            error_message: error.message,
          });
        });

      this.updating = false;
    },

    /**
     * ログアウト処理
     */
    async logout() {
      await firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("ログアウトしました");
          this.$axios.get("/session-logout").then((response) => {
            console.log(response);
            this.$emit("logout");
            this.messageDialog = true;
            logEvent("logout", {
              user_uid: this.$store.getters.getUserUID,
              shop_uid: this.$store.getters.getShopUID,
            });
            if (!location.href.match("/login")) this.$router.push("/" + this.$route.params.uid);
          });
        })
        .catch((error) => {
          console.log(`ログアウト時にエラーが発生しました (${error})`);
        });
    },
  },
};
</script>

<style scoped>
.div-title {
  color: #404040;
}

.theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-shop ::v-deep .v-btn__content {
  justify-content: start;
  width: 100%;
  white-space: normal !important;
  text-transform: none;
}

.btn-top {
  bottom: 105px;
  justify-content: flex-end;
}

.btn-login {
  bottom: 43px;
  justify-content: flex-end;
}

.theme--light.v-btn:focus::before {
  opacity: 0;
}

.sheet-bottom {
  position: fixed;
  bottom: 0px;
  z-index: 3;
}
</style>

<template>
  <v-container class="fill-height d-flex align-content-space-between" fluid>
    <v-row></v-row>

    <!-- タイトル・従業員番号入力 -->
    <v-row class="d-flex flex-column align-center" no-gutters>
      <v-img
        class="mt-12 mb-6"
        v-if="isValidURL"
        :src="require('cumin-common/src/assets/harecord.png')"
        contain
        :height="$vuetify.breakpoint.xs ? 40 : 50"
        width="270"
      />
      <div class="div-shop" :style="{ fontSize }">{{ shop.shopName }}</div>

      <div class="div-form" v-if="isValidURL">
        <div>
          <v-icon class="mb-1">mdi-account-outline</v-icon>
          <span class="text--secondary text-body-2">従業員番号</span>
          <v-text-field
            class="ma-0 text-field"
            v-model="employeeNumber"
            :error-messages="errorMessage"
            solo
            @keydown.enter="login()"
            style="width: 200px"
          />
        </div>
        <v-btn
          class="ml-4 mt-7 primary--text"
          color="primary"
          icon
          x-large
          :loading="loading"
          :disabled="loading"
          @click="login()"
        >
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </div>

      <DialogQrLogin
        ref="dialogQrLogin"
        :shop="shop"
        :headquarters="headquarters"
        :openMenu="openMenu"
      />
    </v-row>

    <!-- お知らせ・店舗選択ボタン・ライセンス条項 -->
    <v-row v-if="isValidURL" no-gutters style="height: 238px">
      <v-col class="mb-5 d-flex justify-center" cols="12">
        <InfomationCard />
      </v-col>
      <v-col cols="4">
        <v-btn
          class="pt-1 pl-4 white primary--text"
          v-if="headquarters.uid"
          fab
          bottom
          left
          elevation="2"
          @click="changeShop()"
          style="justify-content: start"
        >
          <v-icon class="mr-5">icon-store-search-outline</v-icon>
          <span v-if="!$vuetify.breakpoint.xs">店舗選択画面へ</span>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-center align-end" cols="4">
        <v-btn class="text-caption" color="primary" text href="/license-provision" target="_blank">
          ライセンス条項
        </v-btn>
      </v-col>
    </v-row>

    <!-- パスワードリセットダイアログ -->
    <v-dialog v-model="isOpenedFirstLoginDialog" persistent width="auto ">
      <v-card class="pa-2 pa-sm-4">
        <v-card-title class="pa-4 text-subtitle-1" style="white-space: pre-wrap">{{
          "初回ログインのため、パスワード再設定の案内メールを送信しました。\nメールの内容に従って、パスワード設定をお願いします。"
        }}</v-card-title>
        <v-card-text class="px-4 pb-4">送信先：{{ mailAddress }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isOpenedFirstLoginDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 確認者・承認者のパスワードダイアログ -->
    <LoginPasswordDialog
      ref="passwordDialog"
      :dialog="isOpenedLoginPasswordDialog"
      :mailAddress="mailAddress"
      :createSession="createSession"
      :openMenu="openMenu"
      @close="isOpenedLoginPasswordDialog = false"
    />
  </v-container>
</template>

<script>
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import InfomationCard from "../organisms/InfomationCard";
import LoginPasswordDialog from "../organisms/LoginPasswordDialog";
import DialogQrLogin from "../organisms/DialogQrLogin";

export default {
  head: {
    title: {
      inner: "Login",
    },
  },
  components: {
    InfomationCard,
    LoginPasswordDialog,
    DialogQrLogin,
  },
  props: {
    shop: Object,
    headquarters: Object,
    isValidURL: Boolean,
    changeShop: {
      type: Function,
      required: true,
    },
    openMenu: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    isDisabled: false,
    fontSize: "",
    employeeNumber: "",
    errorMessage: "",
    mailAddress: "",
    isOpenedFirstLoginDialog: false,
    isOpenedLoginPasswordDialog: false,
  }),
  mounted: function () {
    this.getShopFontSize;
    window.addEventListener("resize", this.getShopFontSize);
  },
  methods: {
    /**
     * 店舗名の文字サイズを画面幅に合わせる
     */
    getShopFontSize() {
      const maxWidth = window.innerWidth * 0.8;

      let fontSize = maxWidth < 400 ? 28 : 36;
      const dynamicText = document.querySelector(".div-shop");
      dynamicText.style.fontSize = `${fontSize}px`;

      while (dynamicText.scrollWidth > maxWidth && fontSize > 12) {
        fontSize--;
        if (fontSize <= 12) break;
        dynamicText.style.fontSize = `${fontSize}px`;
      }
    },

    /**
     * ログイン処理
     */
    async login() {
      if (this.employeeNumber.trim() == "") {
        this.errorMessage = "入力してください";
        return;
      }

      this.errorMessage = "";
      this.loading = true;

      // 従業員番号が選択した店舗に存在するかを確認
      let userDoc = {};
      let headquartersDoc = {};
      await db
        .collection("users")
        .where("shopUID", "==", this.shop.uid)
        .where("employeeNumber", "==", this.employeeNumber)
        .get()
        .then((users) => (userDoc = users.docs[0]))
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "login",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      // 選択した店舗に存在しない且つ本部情報が取得済の場合、同じ本部の店舗に所属しているか確認
      if (!userDoc && this.headquarters.uid) {
        // 従業員UIDのみで検索
        await db
          .collection("users")
          .where("employeeNumber", "==", this.employeeNumber)
          .get()
          .then(async (users) => {
            if (users.empty) return;

            // 最新の本部情報を取得
            await db
              .collection("headquarters")
              .doc(this.headquarters.uid)
              .get()
              .then((headquarters) => {
                if (!headquarters.exists) return;
                headquartersDoc = headquarters;

                // 所属店舗UIDが本部の店舗一覧に存在するか確認
                for (const user of users.docs) {
                  const shops = headquarters.data().shopUID;
                  if (shops.includes(user.data().shopUID)) {
                    userDoc = user;
                    break;
                  }
                }
              })
              .catch((error) => {
                logEvent("error_db_read", {
                  method_name: "login",
                  error_message: error.message,
                });
                this.$router.replace({
                  name: "SystemError",
                  params: { 0: location.pathname, error: error.message },
                });
              });
          })
          .catch((error) => {
            logEvent("error_db_read", {
              method_name: "login",
              error_message: error.message,
            });
            this.$router.replace({
              name: "SystemError",
              params: { 0: location.pathname, error: error.message },
            });
          });
      }

      if (!userDoc) {
        this.errorMessage = "従業員番号に誤りがあります\nもう一度入力してください";
        this.loading = false;
        return;
      }

      // 一般従業員の場合、端末保存店舗以外でログインできないようにする。
      if (this.headquarters.uid) {
        const localUID = localStorage.getItem("shopUID");
        if (!!localUID && this.shop.uid != localUID && userDoc.data().authority == "general") {
          this.errorMessage = "端末に保存されている店舗で\nログインしてください";
          this.loading = false;
          return;
        }
      }

      // 最新の本部情報を取得
      if (!headquartersDoc.exists && !!this.headquarters.uid) {
        headquartersDoc = await db
          .collection("headquarters")
          .doc(this.headquarters.uid)
          .get()
          .catch((error) => {
            logEvent("error_db_read", {
              method_name: "login",
              error_message: error.message,
            });
            this.$router.replace({
              name: "SystemError",
              params: { 0: location.pathname, error: error.message },
            });
          });
      }

      // セッションの本部情報を更新
      this.$store.commit(
        headquartersDoc.exists ? "setHeadquartersInfo" : "clearHeadquarters",
        headquartersDoc
      );

      // セッションの店舗情報を更新
      const shopDoc = await db
        .collection("shops")
        .doc(this.shop.uid)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "login",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      if (shopDoc.exists) this.$store.commit("setShopInfo", shopDoc);

      // セッションのユーザー情報を更新
      this.$store.commit("setUserInfo", {
        userUID: userDoc.id,
        authority: userDoc.data().authority,
        employeeNumber: userDoc.data().employeeNumber,
        mailAddress: userDoc.data().mailAddress,
        name: userDoc.data().name,
        position: userDoc.data().position,
        isHelp: userDoc.data().shopUID != shopDoc.id,
      });

      const user = this.$store.getters.getUser;

      // セッションに権限情報を保存
      this.$store.commit("setAuth", user.authority);

      const shop = JSON.parse(JSON.stringify(this.$store.getters.getShop));
      delete shop.createdAt;
      const headquartersUID = this.$store.getters.getHeadquartersUID;
      if (headquartersUID) shop.headquartersUID = headquartersUID;
      this.mailAddress = user.mailAddress;

      // 一般ユーザーの場合
      if (user.authority == "general") {
        const loginResult = await this.createSession({ user, shop });
        if (loginResult == "success") {
          logEvent("login", {
            user_uid: this.$store.getters.getUserUID,
            shop_uid: this.$store.getters.getShopUID,
            window_size: `${window.innerWidth}px * ${window.innerHeight}px`,
            user_agent: navigator.userAgent,
            authority: user.authority,
          });
          this.openMenu();
        } else {
          this.errorMessage = "ログイン処理にエラーが発生しました";
        }
      }

      // 確認者・承認者・adminユーザーの場合
      if (user.authority != "general") {
        // 初回ログインの場合、パスワードリセット依頼のメール送信
        if (userDoc.data().passwordChangeFlag == false) {
          await this.sendPasswordResetMail();
          this.isOpenedFirstLoginDialog = true;
        } else {
          this.isOpenedLoginPasswordDialog = true;
        }
      }

      this.loading = false;
    },

    /**
     * firebase functionsにて認証処理
     * @param {oblect} body
     */
    async createSession(body) {
      return await this.$axios
        .post("/create-session", body)
        .then((response) => {
          if (response.data.status == "error") {
            logEvent("error_login", {
              user_uid: this.$store.getters.getUserUID,
              shop_uid: this.$store.getters.getShopUID,
              user_agent: navigator.userAgent,
              method_name: response.data.method,
              error_message: response.data.error.message,
            });
          }
          return response.data.status;
        })
        .catch((error) => {
          console.log(error);
          logEvent("error_login", {
            user_uid: this.$store.getters.getUserUID,
            shop_uid: this.$store.getters.getShopUID,
            error_message: error.message,
          });
          return "error";
        });
    },

    /**
     * パスワードリセットのメール送信
     */
    async sendPasswordResetMail() {
      await firebase
        .auth()
        .sendPasswordResetEmail(this.mailAddress)
        .then(function () {
          console.log("メール送信完了");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.div-shop {
  color: #404040;
  height: 100px;
}

.div-form {
  display: flex;
  height: 106px;
}

::v-deep .v-messages {
  white-space: pre-wrap;
}
</style>

<template>
  <v-dialog v-model="dialog" persistent width="85vw" max-width="1000">
    <v-card class="pa-2 pa-sm-4">
      <v-row class="pt-2 pt-sm-4" no-gutters>
        <v-col
          class="px-2 pb-2 pb-sm-4"
          v-for="menu in menus"
          :key="menu.title"
          :cols="menu.cols"
          :sm="menu.sm"
        >
          <v-row no-gutters justify="start">
            <v-col cols="12">
              <span class="px-2 text-body-2 text-sm-body-1">
                {{ menu.title }}
              </span>
            </v-col>
            <v-col class="pb-3" cols="12"><v-divider></v-divider></v-col>
            <v-col
              class="pb-2 d-flex flex-column align-center"
              v-for="(app, index) in apps.filter((e) => e.category == menu.category)"
              :key="index"
              :cols="4 * (12 / menu.cols)"
              :sm="2 * (12 / menu.sm)"
            >
              <v-btn
                class="pt-1 rounded-lg"
                :class="
                  app.isFree
                    ? ''
                    : $store.state.shop[app.key]
                    ? 'tertiary primary--text'
                    : 'primary--text'
                "
                :color="app.isFree ? 'primary' : 'tertiary'"
                :height="buttonSize"
                :width="buttonSize"
                depressed
                :outlined="app.isFree"
                :disabled="!$store.state.shop[app.key]"
                @click="siteTransition(app)"
              >
                <div class="d-flex flex-column text-caption text-sm-body-2">
                  <v-icon :size="$vuetify.breakpoint.xs ? 40 : $vuetify.breakpoint.sm ? 48 : 70">
                    {{ app.icon }}
                  </v-icon>
                  <span
                    class="mt-2 text--primary"
                    :class="$vuetify.breakpoint.mdAndUp ? 'text-body-1' : ''"
                  >
                    {{ app.appName }}
                  </span>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <DialogTimeout ref="dialogTimeout" />
  </v-dialog>
</template>

<script>
import firebase from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import dbProcess from "cumin-common/src/mixins/dbProcess";

export default {
  props: {
    dialog: Boolean,
  },
  mixins: [dbProcess],
  data: () => ({
    menus: [
      { title: "HACCP帳票", category: "haccp", cols: "12", sm: "12" },
      { title: "その他帳票", category: "individual", cols: "12", sm: "8" },
      { title: "店舗設定", category: "config", cols: "12", sm: "4" },
    ],
    apps: [],
    intervalId: "",
  }),
  watch: {
    dialog() {
      if (this.dialog) this.setTimer();
    },
  },
  created() {
    this.loadApps();
  },
  computed: {
    /**
     * 画面幅からボタンサイズの取得
     */
    buttonSize() {
      const bp = this.$vuetify.breakpoint;
      const screenWidth = bp.width;
      const dialogWidth = screenWidth * 0.85;
      const cardWidth = dialogWidth >= 1000 ? 1000 : dialogWidth;
      const areaWidth = cardWidth - (bp.xs ? 32 : 48);
      const buttonCount = bp.xs ? 3 : 6;
      return areaWidth / buttonCount - 8;
    },
  },
  methods: {
    /**
     * アプリ情報の取得
     */
    async loadApps() {
      this.apps = await this.getQueryDoc({
        collection: "appMenu",
        order: [{ fieldPath: "order", directionStr: "asc" }],
      });
    },

    /**
     * 1時間操作がない場合はログアウト
     */
    setTimer() {
      const limitTime = 1000 * 60 * 60;
      let setDate = Date.now() + limitTime;

      this.intervalId = setInterval(() => {
        if (Date.now() > setDate) {
          clearInterval(this.intervalId);
          logEvent("time_out", {
            user_uid: this.$store.getters.getUserUID,
            shop_uid: this.$store.getters.getShopUID,
          });
          this.logout();
        }
      }, 500);

      // クリックイベントでタイマーリセット
      document.addEventListener("click", function () {
        setDate = Date.now() + limitTime;
      });
    },

    /**
     * サイト遷移
     */
    siteTransition(app) {
      clearInterval(this.intervalId);
      const url = app.origin + "/" + this.$route.params.uid;
      window.location.href = url + app.path;
    },

    /**
     * ログアウト処理
     */
    async logout() {
      await firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("ログアウトしました");
          this.$axios.get("/session-logout").then((response) => {
            console.log(response);
            this.$refs.dialogTimeout.$data.dialog = true;
          });
        })
        .catch((error) => {
          console.log(`ログアウト時にエラーが発生しました (${error})`);
        });
    },
  },
};
</script>

<style scoped>
::v-deep .v-btn {
  text-transform: inherit;
}
</style>

/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Window from "../components/pages/Window.vue";
import PasswordReset from "../components/pages/PasswordReset.vue";
import LicenseProvision from "../components/pages/LicenseProvision.vue";
import PageSystemError from "cumin-common/src/components/pages/PageSystemError.vue";
import PageNotFound from "cumin-common/src/components/pages/PageNotFound.vue";

if (!process || process.env.NODE_ENV !== "test") Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:uid/login",
    name: "Login",
    component: Window,
  },
  {
    path: "/:uid/shop-select",
    name: "ShopSelect",
    component: Window,
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/license-provision",
    name: "LicenseProvision",
    component: LicenseProvision,
  },
  {
    path: "/:uid",
    redirect: { name: "Login" },
  },
  {
    path: "*",
    name: "NotFound",
    component: PageNotFound,
  },
  {
    path: "*",
    name: "SystemError",
    component: PageSystemError,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueHead from "vue-head";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import apendExtendRules from "./plugins/veeValidate";
import common from "cumin-common";

Vue.config.productionTip = false;

Vue.use(VueHead, {
  separator: "",
});

Vue.use(common);

Vue.prototype.$axios = axios;
axios.defaults.withCredentials = true;

apendExtendRules();
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

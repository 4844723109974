var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height d-flex justify-center",attrs:{"fluid":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.isShowForm || _vm.isExpiredCode)?[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","align-self":"center"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"src":require('cumin-common/src/assets/harecord.png'),"contain":"","height":"50","width":"270"}})],1)])]:_vm._e(),(_vm.isShowForm)?[_c('v-col',{staticClass:"mb-10",attrs:{"cols":"12","align-self":"center"}},[_c('h2',{staticClass:"div-title d-flex justify-center"},[_vm._v("パスワード変更")])]),_c('v-col',{attrs:{"cols":"2","sm":"4"}}),_c('v-col',{attrs:{"cols":"8","sm":"4","align-self":"center"}},[_c('div',[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v(" 新規パスワード ")],1),_c('validation-provider',{attrs:{"rules":{
                required: true,
                min: 6,
                regex: '^[0-9a-zA-Z]+$',
              },"name":"パスワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0",attrs:{"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"solo":"","error-messages":errors},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2","sm":"4"}}),_c('v-col',{attrs:{"cols":"2","sm":"4"}}),_c('v-col',{attrs:{"cols":"8","sm":"4","align-self":"center"}},[_c('div',[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v(" 新規パスワード(確認用) ")],1),_c('validation-provider',{attrs:{"rules":{
                required: true,
                min: 6,
                regex: '^[0-9a-zA-Z]+$',
              },"name":"確認パスワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ma-0",attrs:{"type":_vm.showCheckPassword ? 'text' : 'password',"append-icon":_vm.showCheckPassword ? 'mdi-eye-off' : 'mdi-eye',"solo":"","error-messages":_vm.validatePassword(errors[0])},on:{"click:append":function($event){_vm.showCheckPassword = !_vm.showCheckPassword}},model:{value:(_vm.checkPassword),callback:function ($$v) {_vm.checkPassword=$$v},expression:"checkPassword"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2","sm":"4"}}),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.toDisableButton(invalid),"type":"submit","x-large":"","color":"primary","elevation":"6"}},[_vm._v(" 変更する ")])],1)]),_c('v-col',{attrs:{"cols":"4"}})]:_vm._e(),(_vm.isExpiredCode)?[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"8","align-self":"center"}},[_c('div',{staticClass:"mt-8 d-flex justify-center"},[_vm._v(" パスワード再設定リンクの有効期限が切れたか、リンクがすでに使用されています。 ")])]),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"8","align-self":"center"}},[_c('div',{staticClass:"mt-2 d-flex justify-center"},[_vm._v(" パスワードの再設定をもう一度お試しください。 ")])]),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('div',{staticClass:"mt-10 d-flex justify-center"},[_c('v-btn',{attrs:{"x-large":"","color":"primary","elevation":"6"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("閉じる")])],1)]),_c('v-col',{attrs:{"cols":"4"}})]:_vm._e()],2)],1)]}}])}),_c('DialogMessage',{attrs:{"dialog":_vm.isOpenedMessageDialog,"message":_vm.message},on:{"close":function($event){return _vm.closeDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
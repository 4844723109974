import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: process.env.VUE_APP_PRIMARY_COLOR,
        secondary: process.env.VUE_APP_SECONDARY_COLOR,
        tertiary: process.env.VUE_APP_TERTIARY_COLOR,
        required: "#e88f21",
        "bg-required": "#fffbe6",
      },
    },
  },
});

<template>
  <v-window v-model="windowIndex" touchless>
    <v-window-item>
      <ShopSelect
        ref="shopSelect"
        :isAuthenticated="isAuthenticated"
        :selectShop="selectShop"
        :openMenu="() => (menuDialog = true)"
        @logout="isAuthenticated = false"
      />
    </v-window-item>
    <v-window-item>
      <Login
        ref="login"
        :isValidURL="isValidURL"
        :shop="shop"
        :headquarters="headquarters"
        :changeShop="changeShop"
        :openMenu="() => (menuDialog = true)"
      />
    </v-window-item>
    <MenuDialog :dialog="menuDialog" @close="menuDialog = false" />
  </v-window>
</template>

<script>
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import moment from "moment";
import Login from "../pages/Login";
import ShopSelect from "../organisms/ShopSelect";
import MenuDialog from "../organisms/MenuDialog";

export default {
  components: {
    Login,
    ShopSelect,
    MenuDialog,
  },
  data: () => ({
    windowIndex: 1,
    isValidURL: null,
    isAuthenticated: false,
    shop: {},
    headquarters: {},
    menuDialog: false,
  }),
  mounted: async function () {
    const uid = this.$route.params.uid;
    const path = location.href.match("/shop-select") ? "/shop-select" : "";

    // 店舗・本部情報を取得
    this.isValidURL = await this.loadShop(uid);
    if (!this.isValidURL) {
      this.$router.push({
        name: "NotFound",
        params: { 0: location.pathname },
        replace: true,
      });
    }
    if (this.shop.uid != undefined) {
      this.$refs.login.getShopFontSize();
      this.$refs.login.$refs.dialogQrLogin.loadShop();
    }
    const existsHead = this.headquarters.uid != undefined;

    // 本部無しで、URLの末尾がshop-selectの場合
    if (!existsHead && path == "/shop-select") this.$router.push(`/${uid}`);

    // 本部有りの場合
    if (this.isValidURL && existsHead) {
      const itsHeadURL = uid == this.headquarters.uid;

      // 各アプリからの店舗選択リンクで遷移した場合、事前認証処理
      if (itsHeadURL && path == "/shop-select") {
        this.windowIndex = 0;
        await this.verifySession();
        if (!this.isAuthenticated) this.$router.push(`/${uid}`);
        if (this.shop.uid != undefined)
          setTimeout(() => this.$refs.shopSelect.clickShop(this.shop));
      }

      // 店舗URLの場合、本部URLに変更
      if (!itsHeadURL) this.$router.push(`/${this.headquarters.uid}`);

      // 店舗情報が取得できなかった場合、店舗選択画面へ遷移
      if (this.shop.uid == undefined) this.windowIndex = 0;
    }
  },
  methods: {
    /**
     * URLのuidから店舗・本部情報を取得
     * @param {string} uid
     * @return {boolean} 有効なURLであるか
     */
    async loadShop(uid) {
      // セッションの店舗情報が当日の場合
      if (this.$store.getters.getCreatedAt == moment().format("YYYY/MM/DD")) {
        console.log("セッションの店舗情報が当日");

        // URLのUIDがセッションの店舗UIDと一致する
        if (uid == this.$store.getters.getShopUID) {
          console.log("URLのUIDとセッション情報の店舗UIDが一致");

          // 本部がある店舗の場合、本部URLへリダイレクト
          const headquarter = this.$store.getters.getHeadquarters;
          if (headquarter.headquartersUID != "") {
            console.log("本部がある店舗");
            this.headquarters.uid = headquarter.headquartersUID;
            this.headquarters.shops = headquarter.shops;
          }

          this.shop.uid = uid;
          this.shop.shopName = this.$store.getters.getShopName;
          this.shop.selectShopName = this.$store.getters.getSelectShopName;
          return true;
        }

        // URLのUIDがセッションの本部UIDと一致する
        const headquarter = this.$store.getters.getHeadquarters;
        if (uid == headquarter.headquartersUID) {
          console.log("URLのUIDとセッション情報の本部UIDが一致");

          this.headquarters.uid = headquarter.headquartersUID;
          this.headquarters.shops = headquarter.shops;

          // 本部に存在していた場合、localStorageの店舗情報を取得
          if (this.headquarters.shops.includes(localStorage.getItem("shopUID"))) {
            this.shop.uid = localStorage.getItem("shopUID");
            this.shop.shopName = localStorage.getItem("shopName");
            this.shop.selectShopName = localStorage.getItem("selectShopName");
          } else {
            localStorage.removeItem("shopUID");
            localStorage.removeItem("shopName");
            localStorage.removeItem("selectShopName");
          }
          return true;
        }
      }

      // URLのUIDで本部を検索
      const headquarters = await db
        .collection("headquarters")
        .doc(uid)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "loadShop",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      if (headquarters.exists) {
        console.log("URLのUIDが本部UIDの場合");

        this.headquarters.uid = headquarters.id;
        this.headquarters.shops = headquarters.data().shopUID;

        // 本部に存在していた場合、localStorageの店舗名を表示
        if (this.headquarters.shops.includes(localStorage.getItem("shopUID"))) {
          this.shop.uid = localStorage.getItem("shopUID");
          this.shop.shopName = localStorage.getItem("shopName");
          this.shop.selectShopName = localStorage.getItem("selectShopName");
        } else {
          localStorage.removeItem("shopUID");
          localStorage.removeItem("shopName");
          localStorage.removeItem("selectShopName");
        }
        return true;
      }

      // URLのUIDで店舗を検索
      const shop = await db
        .collection("shops")
        .doc(uid)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "loadShop",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      if (shop.exists && shop.data().deletedFlag == false) {
        console.log("URLのUIDが店舗UIDの場合");

        // 本部がある店舗の場合、本部URLへリダイレクト
        const headquarter = await db
          .collection("headquarters")
          .where("shopUID", "array-contains", uid)
          .get()
          .then((headquarters) => headquarters.docs[0])
          .catch((error) => {
            logEvent("error_db_read", {
              method_name: "loadShop",
              error_message: error.message,
            });
            this.$router.replace({
              name: "SystemError",
              params: { 0: location.pathname, error: error.message },
            });
          });

        if (headquarter) {
          console.log("本部がある店舗");
          this.headquarters.uid = headquarter.id;
          this.headquarters.shops = [...headquarter.data().shopUID];
        }

        this.shop.uid = shop.id;
        this.shop.shopName = shop.data().shopName;
        this.shop.selectShopName = shop.data().selectShopName;
        return true;
      }

      // 存在しないURL
      console.log("存在しないURL");
      return false;
    },

    /**
     * セッションcookieを使用して再認証、ユーザー・店舗情報を取得
     * @return {object} 店舗情報
     */
    async verifySession() {
      let cookieUID = "";
      for (const cookie of document.cookie.split("; ")) {
        if (cookie.split("=")[0] == "userUID") cookieUID = cookie.split("=")[1];
      }
      if (!cookieUID) return;

      // functions関数にてセッションcookieを確認
      await this.$axios
        .get("/verify-session")
        .then((response) => {
          const user = response.data.claims.user;
          const shop = response.data.claims.shop;
          if (this.headquarters.shops.includes(shop.shopUID)) {
            if (cookieUID == user.userUID) {
              console.log("事前認証");
              this.$refs.login._data.employeeNumber = user.employeeNumber;
              this.$store.commit("setUserInfo", user);

              this.shop.uid == "";
              this.shop.uid = shop.shopUID;
              this.shop.shopName = shop.shopName;
              this.shop.selectShopName = shop.selectShopName;
              this.isAuthenticated = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     * ログイン画面から店舗選択画面へ遷移
     */
    changeShop() {
      this.windowIndex = 0;
      setTimeout(() => this.$refs.shopSelect.clickShop(this.shop));
    },

    /**
     * 店舗選択画面からログイン画面へ遷移
     */
    selectShop(shop) {
      this.shop = {
        uid: shop.uid,
        shopName: shop.shopName,
        selectShopName: shop.selectShopName,
      };
      this.$refs.login._data.employeeNumber = "";
      this.windowIndex = 1;
      setTimeout(() => this.$refs.login.getShopFontSize());
    },
  },
};
</script>

<style scoped>
.v-window {
  height: 100%;
}

.v-window-item {
  height: 100%;
}
</style>

<template>
  <div class="py-2">
    <v-btn v-if="smartLogin" color="primary" text large @click="dialog = true">
      <v-icon size="32">mdi-qrcode-scan</v-icon>
      <span class="ml-2 text-body-2">スマートログイン</span>
    </v-btn>
    <v-dialog v-model="dialog" persistent width="auto ">
      <v-card class="pa-2 pa-sm-4 text-caption" color="#f5f5f5">
        <v-card-title class="pa-4">
          <span>スマートログイン用コードでログイン</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="stopCamera()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-sheet max-width="500" height="500">
          <QrcodeStream v-if="dialog" :camera="camera" @decode="onDecode" @init="onInit">
            <div class="d-flex justify-center align-center" style="height: 100%">
              <div class="d-flex flex-column align-center" v-if="loading" style="width: 80px">
                <v-icon class="mb-1" color="primary" large>mdi-camera-outline</v-icon>
                <span>起動中…</span>
                <v-progress-linear indeterminate color="primary" size="60" width="6" />
              </div>
              <v-progress-circular
                v-if="isConfirmed && !error"
                :size="80"
                :width="5"
                indeterminate
                color="white"
              />
              <span class="red--text" v-if="error">{{ error }}</span>
            </div>
          </QrcodeStream>
        </v-sheet>
        <span class="red--text">{{ resultError }}</span>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import CryptoJS from "crypto-js";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import { logEvent } from "../../plugins/firebase";
import { db } from "../../plugins/firebase";

export default {
  components: {
    QrcodeStream,
  },
  mixins: [dbProcess],
  props: {
    shop: Object,
    headquarters: Object,
    openMenu: Function,
  },
  data: () => ({
    dialog: false,
    smartLogin: false,
    cardSize: 300,
    camera: "auto",
    loading: true,
    isConfirmed: false,
    result: "",
    error: "",
    resultError: "",
  }),
  watch: {
    shop: {
      handler: function () {
        this.loadShop();
      },
      deep: true,
    },
  },
  methods: {
    /**
     * スマートログイン可能店舗かの確認
     */
    async loadShop() {
      if (!this.shop.uid) return;
      const shopDoc = await this.getDoc({ collection: "shops", documentPath: this.shop.uid });
      this.smartLogin = !!shopDoc.smartLogin;
    },

    /**
     * カメラ初期化
     */
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          // カメラアクセス許可を付与する必要があります
          this.error = "ERROR: you need to grant camera access permisson";
          this.stopCamera();
        } else if (error.name === "NotFoundError") {
          // このデバイスにはカメラがありません
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          // 安全なコンテキストが必要です（HTTPS、ローカルホスト）
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          // カメラはすでに使用されていますか？
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          // 設置されたカメラは適切ではありません
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          // ストリームAPIはこのブラウザではサポートされていません
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      } finally {
        this.isConfirmed = this.camera === "off";
        this.loading = false;
      }
    },

    /**
     * カメラの停止
     */
    stopCamera() {
      this.loading = true;
      this.isConfirmed = false;
      this.result = "";
      this.error = "";
      this.resultError = "";
      this.dialog = false;
    },

    /**
     * QRコード読み込みに成功
     */
    async onDecode(result) {
      // const shopUid = result.substring(0, 20);
      const userUid = result.substring(20, 40);
      const cipher = result.substring(40);

      // UIDの書式チェック
      if (!/[a-zA-Z0-9]{20}/.test(userUid)) {
        this.resultError = "スマートログイン用のコードではありません";
        return;
      }

      // ユーザー情報の取得
      const userDoc = await this.getDoc({ collection: "users", documentPath: userUid });
      if (userDoc.shopUID != this.shop.uid) {
        if (!this.headquarters.shops || !this.headquarters.shops.includes(userDoc.shopUID)) {
          this.resultError = "店舗に存在しないユーザーです";
          return;
        }
      }

      // 一般従業員の場合、端末保存店舗以外でログインできないようにする。
      if (this.headquarters.uid) {
        const localUID = localStorage.getItem("shopUID");
        if (!!localUID && this.shop.uid != localUID && userDoc.authority == "general") {
          this.resultError = "端末に保存されている店舗でログインしてください";
          return;
        }
      }

      this.camera = "off";

      // セッションの本部情報を更新
      const headquartersDoc = await db
        .collection("headquarters")
        .doc(this.headquarters.uid)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "login",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });
      this.$store.commit(
        headquartersDoc.exists ? "setHeadquartersInfo" : "clearHeadquarters",
        headquartersDoc
      );

      // セッションの店舗情報を更新
      const shopDoc = await db
        .collection("shops")
        .doc(this.shop.uid)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "login",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });
      this.$store.commit("setShopInfo", shopDoc);

      // セッションのユーザー情報を更新
      this.$store.commit("setUserInfo", {
        userUID: userDoc.id,
        authority: userDoc.authority,
        employeeNumber: userDoc.employeeNumber,
        mailAddress: userDoc.mailAddress,
        name: userDoc.name,
        position: userDoc.position,
        isHelp: userDoc.shopUID != shopDoc.id,
      });
      const user = this.$store.getters.getUser;

      // セッションに権限情報を保存
      this.$store.commit("setAuth", user.authority);

      let loginResult = "";

      const shop = JSON.parse(JSON.stringify(this.$store.getters.getShop));
      delete shop.createdAt;
      const headquartersUID = this.$store.getters.getHeadquartersUID;
      if (headquartersUID) shop.headquartersUID = headquartersUID;

      // 一般ユーザーの場合
      if (user.authority == "general") loginResult = await this.createSession({ user, shop });

      // 確認者・承認者・adminユーザーの場合
      if (user.authority != "general") {
        const bytes = CryptoJS.AES.decrypt(cipher, "h@rec0rd");
        const password = bytes.toString(CryptoJS.enc.Utf8) || "blank";
        loginResult = await this.createSession({ user, shop, password });
      }

      if (loginResult == "success") {
        logEvent("login", {
          user_uid: this.$store.getters.getUserUID,
          shop_uid: this.$store.getters.getShopUID,
          window_size: `${window.innerWidth}px * ${window.innerHeight}px`,
          user_agent: navigator.userAgent,
          authority: user.authority,
        });
        this.openMenu();
        this.dialog = false;
      } else if (loginResult == "auth-error") {
        this.resultError = "パスワードに誤りがあります。手動入力でログインしてください";
      } else {
        this.resultError = "ログイン処理にエラーが発生しました";
      }

      this.camera = "auto";
    },

    /**
     * firebase functionsにて認証処理
     * @param {oblect} body
     */
    async createSession(body) {
      return await this.$axios
        .post("/create-session", body)
        .then((response) => {
          if (response.data.status == "error") {
            logEvent("error_login", {
              user_uid: this.$store.getters.getUserUID,
              shop_uid: this.$store.getters.getShopUID,
              user_agent: navigator.userAgent,
              method_name: response.data.method,
              error_message: response.data.error.message,
            });
          }
          return response.data.status;
        })
        .catch((error) => {
          console.log(error);
          logEvent("error_login", {
            user_uid: this.$store.getters.getUserUID,
            shop_uid: this.$store.getters.getShopUID,
            error_message: error.message,
          });
          return "error";
        });
    },
  },
};
</script>

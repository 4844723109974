<template>
  <v-container fluid class="fill-height d-flex justify-center">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-row no-gutters>
          <template v-if="isShowForm || isExpiredCode">
            <v-col class="mb-5" cols="12" align-self="center">
              <div class="d-flex justify-center">
                <v-img
                  :src="require('cumin-common/src/assets/harecord.png')"
                  contain
                  height="50"
                  width="270"
                />
              </div>
            </v-col>
          </template>
          <template v-if="isShowForm">
            <v-col class="mb-10" cols="12" align-self="center">
              <h2 class="div-title d-flex justify-center">パスワード変更</h2>
            </v-col>
            <v-col cols="2" sm="4"></v-col>
            <v-col cols="8" sm="4" align-self="center">
              <div>
                <v-icon>mdi-lock</v-icon>
                新規パスワード
              </div>
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  min: 6,
                  regex: '^[0-9a-zA-Z]+$',
                }"
                name="パスワード"
              >
                <v-text-field
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  solo
                  class="ma-0"
                  :error-messages="errors"
                  @click:append="showPassword = !showPassword"
                />
              </validation-provider>
            </v-col>
            <v-col cols="2" sm="4"></v-col>
            <v-col cols="2" sm="4"></v-col>
            <v-col cols="8" sm="4" align-self="center">
              <div>
                <v-icon>mdi-lock</v-icon>
                新規パスワード(確認用)
              </div>
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  min: 6,
                  regex: '^[0-9a-zA-Z]+$',
                }"
                name="確認パスワード"
              >
                <v-text-field
                  v-model="checkPassword"
                  :type="showCheckPassword ? 'text' : 'password'"
                  :append-icon="showCheckPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  solo
                  class="ma-0"
                  :error-messages="validatePassword(errors[0])"
                  @click:append="showCheckPassword = !showCheckPassword"
                />
              </validation-provider>
            </v-col>
            <v-col cols="2" sm="4"></v-col>
            <v-col cols="4"></v-col>
            <v-col cols="4" align-self="center">
              <div class="d-flex justify-center">
                <v-btn
                  :loading="loading"
                  :disabled="toDisableButton(invalid)"
                  type="submit"
                  x-large
                  color="primary"
                  elevation="6"
                >
                  変更する
                </v-btn>
              </div>
            </v-col>
            <v-col cols="4"></v-col>
          </template>
          <template v-if="isExpiredCode">
            <v-col cols="2"></v-col>
            <v-col cols="8" align-self="center">
              <div class="mt-8 d-flex justify-center">
                パスワード再設定リンクの有効期限が切れたか、リンクがすでに使用されています。
              </div>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2"></v-col>
            <v-col cols="8" align-self="center">
              <div class="mt-2 d-flex justify-center">
                パスワードの再設定をもう一度お試しください。
              </div>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="4"></v-col>
            <v-col cols="4" align-self="center">
              <div class="mt-10 d-flex justify-center">
                <v-btn @click="closeDialog()" x-large color="primary" elevation="6">閉じる</v-btn>
              </div>
            </v-col>
            <v-col cols="4"></v-col>
          </template>
        </v-row>
      </form>
    </validation-observer>
    <DialogMessage :dialog="isOpenedMessageDialog" :message="message" @close="closeDialog()" />
  </v-container>
</template>

<script>
import CryptoJS from "crypto-js";
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import "firebase/auth";

export default {
  head: {
    title: {
      inner: "パスワード再設定",
    },
  },
  data: () => ({
    isShowForm: false,
    isExpiredCode: false,
    email: "",
    password: "",
    checkPassword: "",
    showPassword: false,
    showCheckPassword: false,
    errorMessage: "",
    loading: false,
    isDisabled: false,
    message: "",
    isOpenedMessageDialog: false,
  }),

  /**
   * ページの有効期限の確認
   */
  created: async function () {
    const actionCode = this.$route.query.oobCode;
    const result = await firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(async function (email) {
        return { status: false, mail: email, message: null };
      })
      .catch(function (error) {
        return { status: true, mail: null, message: error.message };
      });
    if (result.status) {
      this.isExpiredCode = true;
    } else {
      this.isShowForm = true;
    }
    this.email = result.mail;
  },
  computed: {
    /**
     * パスワードのバリデーション
     * @param {string} error バリデーションのエラーメッセージ
     * @return {string} エラーメッセージ
     */
    validatePassword: function () {
      return function (error) {
        if (error != undefined) {
          return error;
        }
        return this.errorMessage;
      };
    },

    /**
     * パスワードリセット処理中はボタンを無効にする
     * @param {object} status バリデーションの監視状態
     * @return {boolean} 非活性かどうか
     */
    toDisableButton: function () {
      return function (status) {
        if (status == false) {
          if (this.isDisabled) {
            return true;
          }
        }
        return status;
      };
    },
  },

  methods: {
    /**
     * 処理前のバリデーション
     */
    async submit() {
      if (this.$refs.observer.validate()) {
        if (this.password == this.checkPassword) {
          this.resetPassword();
        } else {
          this.errorMessage = "パスワードが不一致です";
        }
      }
    },

    /**
     * パスワードリセット処理
     */
    async resetPassword() {
      this.loading = true;
      this.isDisabled = true;

      const actionCode = this.$route.query.oobCode;
      const email = await firebase
        .auth()
        .verifyPasswordResetCode(actionCode)
        .then(async function (email) {
          return email;
        })
        .catch(function (error) {
          console.log(error.message);
          return null;
        });

      // actionCodeが有効なメールアドレスの場合、パスワードリセット
      if (email) {
        const result = await firebase
          .auth()
          .confirmPasswordReset(actionCode, this.password)
          .then((resp) => {
            return { status: "ok", mail: email, data: resp };
          })
          .catch(function (error) {
            return { status: "error", mail: email, data: error };
          });
        if (result.status == "ok") {
          this.message = "パスワード変更しました。";
          const uid = await this.getUserUID(email);
          this.upadatePasswordChangeFlag(uid);
        } else {
          console.log(result.data.message);
          this.message =
            "パスワード変更が失敗しました。\nパスワード再設定リンクの有効期限が切れたか、リンクがすでに使用されています。\nパスワードの再設定をもう一度お試しください。";
        }
      } else {
        this.message =
          "パスワード変更が失敗しました。\nパスワード再設定リンクの有効期限が切れたか、リンクがすでに使用されています。\nパスワードの再設定をもう一度お試しください。";
      }
      this.loading = false;
      this.isOpenedMessageDialog = true;
    },

    /**
     * メールアドレスからauthユーザーのUIDを取得
     * @param {string} email メールアドレス
     */
    async getUserUID(email) {
      const functions = await firebase.app().functions("asia-northeast1");
      const getUserByEmail = functions.httpsCallable("getUserByEmail");
      const result = await getUserByEmail({ email });

      if (result.data.status == "success") return result.data.userRecord.uid;
      if (result.data.status == "error") {
        logEvent("error_function", {
          method_name: result.data.method,
          error_message: result.data.error.message,
        });
      }
      return null;
    },

    /**
     * usersテーブルの該当ユーザーのパスワード変更フラグを更新
     * @param {string} uid
     */
    async upadatePasswordChangeFlag(uid) {
      db.collection("users")
        .doc(uid)
        .set(
          {
            passwordChangeFlag: true,
            password: CryptoJS.AES.encrypt(this.password, "h@rec0rd").toString(),
          },
          {
            merge: true,
          }
        )
        .catch((error) => {
          logEvent("error_db_write", {
            method_name: "upadatePasswordChangeFlag",
            error_message: error.message,
          });
        });
    },

    /**
     * ダイアログを閉じる、ブラウザタブも閉じる
     */
    closeDialog() {
      this.isOpenedMessageDialog = false;
      window.close();
    },
  },
};
</script>

<style scoped>
.div-title {
  color: #404040;
}
</style>

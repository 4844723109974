<template>
  <v-dialog v-model="dialog" persistent width="auto ">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4 d-flex align-baseline text-subtitle-1 text-sm-h6">
        <span>{{ isStore ? "端末への保存" : "端末から削除" }}</span>
        <span class="ml-2 text-body-2 red--text">※承認者のみ</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <div>
          <div>
            <v-icon class="mb-1">mdi-account-outline</v-icon>
            <span class="text--secondary text-body-2">従業員番号</span>
          </div>
          <v-text-field
            v-model="id"
            :error-messages="idErrorMsg"
            solo
            @keydown.enter="$refs.password.onFocus()"
            style="width: 200px"
          />
        </div>
        <div class="d-flex">
          <div>
            <div>
              <v-icon class="pb-1">mdi-lock</v-icon>
              <span class="text--secondary text-body-2">パスワード</span>
            </div>
            <v-text-field
              ref="password"
              v-model="password"
              :type="isShownPassword ? 'text' : 'password'"
              :append-icon="isShownPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :error-messages="passwordErrorMsg"
              solo
              @click:append="isShownPassword = !isShownPassword"
              @keydown.enter="password.trim() != '' ? checkPassword() : ''"
              style="width: 200px"
            />
          </div>
          <v-btn
            class="ml-4 mt-7 primary--text"
            color="primary"
            icon
            x-large
            :loading="loading"
            :disabled="loading || id.trim() == '' || password.trim() == ''"
            @click="checkPassword()"
          >
            <v-icon>
              {{ isStore ? "mdi-content-save-outline" : "mdi-trash-can-outline" }}
            </v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <DialogMessage
      :dialog="messageDialog"
      :message="isStore ? '店舗情報を端末に保存しました。' : '店舗情報を削除しました。'"
      @close="messageDialog = false"
    />
  </v-dialog>
</template>

<script>
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";

export default {
  data: () => ({
    dialog: false,
    loading: false,
    isStore: false,
    id: "",
    idErrorMsg: "",
    password: "",
    passwordErrorMsg: "",
    isShownPassword: false,
    messageDialog: false,
  }),
  methods: {
    /**
     * メニューを開く
     * @param {boolean} isStore 登録ボタンであるか
     */
    async openMenu(isStore) {
      this.isStore = isStore;
      this.id = "";
      this.idErrorMsg = "";
      this.password = "";
      this.passwordErrorMsg = "";
      this.dialog = true;
    },

    /**
     * パスワード認証
     */
    async checkPassword() {
      this.idErrorMsg = "";
      this.passwordErrorMsg = "";
      this.loading = true;

      // 本部所属の店舗情報取得
      const shops = await db
        .collection("headquarters")
        .doc(this.$route.params.uid)
        .get()
        .then((headquarters) => headquarters.data().shopUID)
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "checkPassword",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      // 従業員番号がDBに存在するかを確認、存在すればemailアドレスを取得
      const user = await db
        .collection("users")
        .where("employeeNumber", "==", this.id)
        .get()
        .then((users) => {
          for (const user of users.docs) {
            if (shops.includes(user.data().shopUID)) return user.data();
          }
        })
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "checkPassword",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      if (user == undefined) {
        this.idErrorMsg = "従業員番号に誤りがあります\nもう一度入力してください";
        this.loading = false;
        return;
      }

      if (user.authority != "approver") {
        this.idErrorMsg = "承認者でないため保存と削除は行えません";
        this.loading = false;
        return;
      }

      // すでにログイン済ユーザー情報がある場合はログアウト
      if (firebase.auth().currentUser) firebase.auth().signOut();

      // email,パスワードでログイン
      await firebase
        .auth()
        .signInWithEmailAndPassword(user.mailAddress, this.password)
        .then(() => {
          this.$emit(this.isStore ? "store" : "clear");
          this.dialog = false;
          this.messageDialog = true;
          firebase.auth().signOut();
        })
        .catch((error) => {
          console.log(error);
          this.passwordErrorMsg = "パスワードに誤りがあります。\nもう一度入力してください。";
        });

      this.loading = false;
    },
  },
};
</script>

<style scoped>
::v-deep .v-messages {
  white-space: pre-wrap;
}
</style>

<template>
  <v-sheet color="#f5f5f5" width="100%" max-width="1000">
    <div class="px-4 pb-1 d-flex align-center" no-gutters style="color: #404040">
      <span class="font-weight-bold">お知らせ</span>
      <v-spacer></v-spacer>
      <v-btn color="primary" text :small="$vuetify.breakpoint.xs" @click="openDialog()">
        過去のお知らせを見る
      </v-btn>
    </div>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-sheet
      class="overflow-y-auto"
      color="#f5f5f5"
      :min-height="$vuetify.breakpoint.xs ? 100 : 120"
      :max-height="$vuetify.breakpoint.xs ? 100 : 120"
    >
      <div v-if="items.length == 0 && !loading" class="px-4 pt-2">
        <span class="text-body-2 text--secondary">新しいお知らせはありません</span>
      </div>
      <template v-for="(item, i) in items">
        <v-sheet
          class="px-4 py-2 d-flex flex-column justify-center text-body-2 text--secondary"
          color="#f5f5f5"
          :key="`${i}_${item.title}`"
        >
          <div class="d-flex align-center">
            <div style="min-width: 80px">
              {{ formatDate(item.displayStartAt, "YYYY/MM/DD") }}
            </div>
            <div class="px-4" style="min-width: 140px">
              <v-chip
                :color="item.tagColor"
                outlined
                :small="!$vuetify.breakpoint.xs"
                :x-small="$vuetify.breakpoint.xs"
              >
                {{ item.tagName }}
              </v-chip>
            </div>
            <div v-if="!$vuetify.breakpoint.xs" style="width: 100%; max-width: 100%">
              {{ item.title }}
            </div>
          </div>
          <div v-if="$vuetify.breakpoint.xs">{{ item.title }}</div>
        </v-sheet>
        <v-divider v-if="i < items.length - 1" :key="i"></v-divider>
      </template>
    </v-sheet>

    <!-- 過去のお知らせ一覧 -->
    <v-dialog v-model="dialog" max-width="1000">
      <v-card class="pa-2 pa-sm-4">
        <v-card-title class="pa-4 d-flex">
          <span class="text-subtitle-1 text-sm-h6">お知らせ一覧</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- 検索 -->
        <v-card-text class="px-4 pb-6">
          <v-text-field
            class="text-body-2"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="検索"
            clearable
            hide-details
            dense
            style="width: 300px"
          />
        </v-card-text>

        <!-- 一覧 -->
        <v-data-table
          :headers="[
            { text: '', value: 'tagName', width: '200' },
            { text: '', value: 'title' },
          ]"
          :items="infomations"
          :items-per-page="-1"
          :loading="loading"
          loading-text="読込中"
          :search="search"
          no-results-text="データが見つかりません"
          fixed-header
          hide-default-header
          hide-default-footer
          :mobile-breakpoint="null"
          dense
          height="70vh"
        >
          <template #item="{ item }">
            <!-- タブレット時 -->
            <tr v-if="$vuetify.breakpoint.smAndUp">
              <td>
                <v-sheet class="d-flex align-center transparent" width="200">
                  <span class="text-body-2 text--secondary">
                    {{ formatDate(item.displayStartAt, "YYYY/MM/DD") }}
                  </span>
                  <v-chip class="ml-4" :color="item.tagColor" outlined small>
                    {{ item.tagName }}
                  </v-chip>
                </v-sheet>
              </td>
              <td class="py-2">{{ item.title }}</td>
            </tr>

            <!-- 縦スマホ時 -->
            <tr v-else>
              <td class="py-2" :colspan="2">
                <div class="d-flex flex-column">
                  <v-sheet class="mb-1 d-flex align-center transparent" width="200">
                    <span class="text-body-2 text--secondary">
                      {{ formatDate(item.displayStartAt, "YYYY/MM/DD") }}
                    </span>
                    <v-chip class="ml-2" :color="item.tagColor" outlined x-small>
                      {{ item.tagName }}
                    </v-chip>
                  </v-sheet>
                  <span>{{ item.title }}</span>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { db } from "../../plugins/firebase";
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";

export default {
  mixins: [calcDate, dbProcess],
  data: () => ({
    loading: false,
    dialog: false,
    items: [],
    search: "",
    infomations: [],
  }),
  created: async function () {
    const infoAt = new Date(sessionStorage.getItem("gotInfoAt"));
    const today = new Date(moment().format("YYYY/MM/DD 00:00:00"));

    this.loading = true;
    if (infoAt < today) {
      await this.loadInfomation();
    } else {
      this.items = JSON.parse(sessionStorage.getItem("infomations"));
    }
    this.loading = false;
  },
  methods: {
    /**
     * DBからお知らせ一覧を取得
     */
    async loadInfomation() {
      this.items = await db
        .collection("infomations")
        .orderBy("displayEndAt", "asc")
        .startAt(new Date())
        .get()
        .then((snapshot) => {
          if (snapshot.empty) return [];
          return snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
        });

      // 表示開始日を過ぎているお知らせに絞る
      this.items = this.items.filter(
        (item) => new Date(item.displayStartAt.seconds * 1000) <= new Date()
      );

      // 表示開始日時で降順にソート
      this.items.sort((a, b) => {
        const dateA = new Date(a.displayStartAt.seconds * 1000);
        const dateB = new Date(b.displayStartAt.seconds * 1000);
        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;
        return 0;
      });

      // セッションストレージにお知らせを保存
      sessionStorage.setItem("gotInfoAt", new Date());
      sessionStorage.setItem("infomations", JSON.stringify(this.items));
    },

    /**
     * ダイアログを開く
     */
    async openDialog() {
      this.dialog = true;
      if (this.infomations.length == 0) {
        this.loading = true;
        this.infomations = await this.getQueryDoc({
          collection: "infomations",
          order: [{ fieldPath: "displayStartAt", directionStr: "desc" }],
        });
        this.loading = false;
      }
    },
  },
};
</script>

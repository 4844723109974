import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import moment from "moment-timezone";

Vue.use(Vuex);

/**
 * 店舗のサービス利用状況から判定
 * @param {object} shop
 * @return {boolean} 利用可能かどうかの判定結果
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isUsedService(shop: any, serviceName: string): boolean {
  const service = shop[serviceName];
  if (service?.startAt) {
    const startAt = new Date(service.startAt.seconds * 1000);
    const endAt = new Date(service.endAt.seconds * 1000);
    if (new Date() > startAt && new Date() < endAt) return true;
  }
  return false;
}

export default new Vuex.Store({
  state: {
    user: {
      userUID: "",
      name: "",
      employeeNumber: "",
      authority: "",
      mailAddress: "",
      position: "",
      isHelp: false,
    },
    shop: {
      shopUID: "",
      shopName: "",
      selectShopName: "",
      positions: [],
      closingHour: 24,
      closingMinute: 0,
      defaultResultForHygiene: "",
      temperatureThreshold: "",
      shouldUseLocationApps: [],
      changeablePagingType: false,
      personalHygiene: false,
      internalTemperature: false,
      facility: false,
      dailyReport: false,
      monthlyReport: false,
      criticalControl: false,
      clean: false,
      weightflow: false,
      inspection: false,
      acceptance: false,
      corethermo: false,
      photoReport: false,
      alcoholCheck: false,
      productionreport: false,
      userMaster: false,
      shopSelect: false,
      createdAt: "",
    },
    headquarters: {
      headquartersUID: "",
      shops: [],
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getUserUID: (state) => {
      return state.user.userUID;
    },
    getShop: (state) => {
      return state.shop;
    },
    getShopUID: (state) => {
      return state.shop.shopUID;
    },
    getShopName: (state) => {
      return state.shop.shopName;
    },
    getSelectShopName: (state) => {
      return state.shop.selectShopName;
    },
    getCreatedAt: (state) => {
      return state.shop.createdAt;
    },
    getHeadquarters: (state) => {
      return state.headquarters;
    },
    getHeadquartersUID: (state) => {
      return state.headquarters.headquartersUID;
    },
  },
  mutations: {
    setUserInfo(state, user) {
      state.user.authority = user.authority;
      state.user.employeeNumber = user.employeeNumber;
      state.user.mailAddress = user.mailAddress;
      state.user.name = user.name;
      state.user.position = user.position;
      state.user.userUID = user.userUID;
      state.user.isHelp = user.isHelp;
    },
    setShopInfo(state, shop) {
      state.shop.shopUID = shop.id;
      state.shop.shopName = shop.data().shopName;
      state.shop.selectShopName = shop.data().selectShopName;
      state.shop.positions = shop
        .data()
        .positions.map((e: { positionName: string }) => e.positionName);
      state.shop.closingHour = shop.data().closingHour;
      state.shop.closingMinute = shop.data().closingMinute;
      state.shop.defaultResultForHygiene = shop.data().defaultResultForHygiene;
      state.shop.temperatureThreshold = shop.data().temperatureThreshold;
      state.shop.shouldUseLocationApps = shop.data().shouldUseLocationApps;
      state.shop.changeablePagingType = shop.data().changeablePagingType;
      state.shop.createdAt = moment().tz("Asia/Tokyo").format("YYYY/MM/DD");
      state.shop.personalHygiene = isUsedService(shop.data(), "personalHygiene");
      state.shop.internalTemperature = isUsedService(shop.data(), "internalTemperature");
      state.shop.facility = isUsedService(shop.data(), "facility");
      state.shop.clean = isUsedService(shop.data(), "clean");
      state.shop.weightflow = isUsedService(shop.data(), "weightflow");
      state.shop.inspection = isUsedService(shop.data(), "inspection");
      state.shop.acceptance = isUsedService(shop.data(), "acceptance");
      state.shop.corethermo = isUsedService(shop.data(), "corethermo");
      state.shop.photoReport = isUsedService(shop.data(), "photoReport");
      state.shop.alcoholCheck = isUsedService(shop.data(), "alcoholCheck");
      state.shop.productionreport = isUsedService(shop.data(), "productionreport");
      state.shop.dailyReport = isUsedService(shop.data(), "dailyReport");
      state.shop.monthlyReport = isUsedService(shop.data(), "monthlyReport");
      state.shop.criticalControl = isUsedService(shop.data(), "criticalControl");
    },
    setAuth(state, authority) {
      state.shop.userMaster = false;
      state.shop.shopSelect = false;
      switch (authority) {
        case "approver":
          state.shop.shopSelect = !!state.headquarters.headquartersUID;
          state.shop.userMaster = true;
          break;
        case "general":
          state.shop.monthlyReport = false;
          break;
        case "admin":
          state.shop.userMaster = true;
          state.shop.personalHygiene = false;
          state.shop.internalTemperature = false;
          state.shop.facility = false;
          state.shop.clean = false;
          state.shop.weightflow = false;
          state.shop.inspection = false;
          state.shop.acceptance = false;
          state.shop.corethermo = false;
          state.shop.photoReport = false;
          state.shop.alcoholCheck = false;
          state.shop.productionreport = false;
          state.shop.dailyReport = false;
          state.shop.monthlyReport = false;
          state.shop.criticalControl = false;
          break;
      }
    },
    setHeadquartersInfo(state, headquarters) {
      state.headquarters.headquartersUID = headquarters.id;
      state.headquarters.shops = headquarters.data().shopUID;
    },
    clearHeadquarters(state) {
      state.headquarters = {
        headquartersUID: "",
        shops: [],
      };
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});

<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
    <v-footer id="footer">
      <div class="text-caption text-sm-body-2">ビルド: {{ version }}</div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    version: process.env.VUE_APP_BUILD_VERSION,
  }),
  mounted: function () {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = document.documentElement.clientWidth < 600;

      // 最初に、ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
      const vh = window.innerHeight * 0.01;
      // カスタム変数--vhの値をドキュメントのルートに設定
      document.documentElement.style.setProperty("--vh", vh + "px");
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

#footer {
  background-color: whitesmoke;
}

.v-application--wrap {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}
</style>
